import * as apiMethods from "@src/services";
import parseNetworkError from "@utils/parse-network-error";
// import keysToCamel from "@utils/keys-to-camel";
import getUtmsAndAdditionalData from "@utils/get-utms-and-additional-data";

export const SET_API_CALL_IN_PROGRESS = "SET_API_CALL_IN_PROGRESS";
export const SET_GENERAL_ERRORS = "SET_GENERAL_ERRORS";
export const SET_QUERIES = "SET_QUERIES";
export const CHANGE_SKU = "CHANGE_SKU";

export default {
  state: {
    isAPICallInProgress: false,
    generalErrors: [],
    query: {
      vitrine: null,
    },
  },
  mutations: {
    [SET_API_CALL_IN_PROGRESS]: (state, status) => {
      state.isAPICallInProgress = status;
    },
    [SET_GENERAL_ERRORS]: (state, error) => {
      state.generalErrors.push(error);
    },
    [SET_QUERIES]: (state, values) => {
      state.query = { ...values };
    },
    [CHANGE_SKU]: (state, sku) => {
      state.query = { ...state.query, sku };
    },
  },
  actions: {
    /**
     * Dispatch AJAX calls
     * @param {String} entity e.g. `users`
     * @param {String} action method name eg. `getById`
     */
    async api({ commit }, { entity, action, payload = {}, query, params }) {
      try {
        const response = await apiMethods[entity][action]({
          payload,
          query,
          params,
        });
        return response;
      } catch (error) {
        const errorPayload = {
          [`${entity}_${action}_request`]: parseNetworkError(error),
        };
        commit(SET_GENERAL_ERRORS, errorPayload);
        commit(SET_API_CALL_IN_PROGRESS, false);
        throw new Error(error);
      }
    },
    setQueriesParams({ commit }, query) {
      commit(SET_QUERIES, query);
    },
    changeSku({ commit }, sku) {
      commit(CHANGE_SKU, sku);
    },
    clearSessionData: () => window.sessionStorage.clear(),
  },
  getters: {
    getBonusText: state => {
      return state.query?.bonus || null;
    },
    getQuery: state => {
      return getUtmsAndAdditionalData(state.query);
    },
    company: state => state.query.company || "app",
    currentSku: state => state.query.sku,
    segmento: state => state.query.type,
    isAPICallInProgress: state => state.isAPICallInProgress,
  },
};
